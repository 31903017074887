import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Text, OrbitControls } from '@react-three/drei';
import FontPicker from 'font-picker-react';
import { Iphone } from './Iphone';
import imageCompression from 'browser-image-compression';
import ImageCropper from '../../ImageCropper';
const Scene = (props) => {
  const [is_dragging, set_is_dragging] = useState(false);
  const [image_change, set_image_change] = useState(false);

  const [img_file, set_img_file] = useState(
    props.image_url ? props.image_url : ''
  );
  const bg_img_ref = useRef(null);
  const handleselect = () => {
    bg_img_ref.current.click();
  };
  async function onSelectCoupon(e) {
    try {
      let file = e.target.files?.[0];

      if (!file) return;
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const imgurl = reader.result?.toString() || '';
        set_img_file(imgurl);
        set_image_change(!image_change);
      });
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
    }
  }
  const cropImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 3, // Max size of the compressed file
        maxWidthOrHeight: 3000, // Resize dimensions
        useWebWorker: false, // To speed up compression using web workers
      };

      const croppedFile = await imageCompression(file, options); // Compress the cropped image
      // Pass the compressed cropped file to the parent component if needed
      props.handle_bg_img(croppedFile);

      // Read the compressed cropped image file and update the preview
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imgurl = reader.result?.toString() || '';
        set_img_file(imgurl); // Update the preview with cropped and compressed image
      });
      reader.readAsDataURL(croppedFile);
    } catch (error) {
      console.error('Error cropping the image:', error);
    }
  };

  const handle_is_dragging = (value) => {
    set_is_dragging(value);
  };
  useEffect(() => {
    if (props.is_set_img > 0) bg_img_ref.current.click();
  }, [props.is_set_img]);
  return (
    <>
      <Canvas>
        {!is_dragging ? (
          <OrbitControls
            enableZoom={true}
            // minPolarAngle={Math.PI / 4} // Minimum vertical angle (in radians)
            // maxPolarAngle={(1 * Math.PI) / 4} // Maximum vertical angle (in radians)
            minPolarAngle={Math.PI / 2 - 0.1}
            maxPolarAngle={Math.PI / 2 + 0.1}
            // minAzimuthAngle={-Math.PI / 4} // Minimum horizontal angle (in radians)
            // maxAzimuthAngle={Math.PI / 4} // Maximum horizontal angle (in radians)
          />
        ) : (
          ''
        )}
        <ambientLight intensity={1} />
        <directionalLight intensity={10} position={[0, 0, 3]} color={'white'} />
        <directionalLight
          intensity={10}
          position={[0, 0, -1]}
          color={'white'}
        />

        <Iphone
          is_en={props.is_en}
          handleselect={handleselect}
          url={img_file}
          handle_is_dragging={handle_is_dragging}
          viewportHeight={props.viewportHeight}
          dragBounds={props.dragBounds}
          handle_event_name={props.handle_event_name}
          handle_date={props.handle_date}
          button_color={props.button_color}
          event_name={props.event_name}
          date={props.date}
          is_dark_color={props.is_dark_color}
          handle_bg_img={props.handle_bg_img}
          position={[0.8, 0, 0]}
          rotation={[0, 0, 0]}
          scale={3.4}
          handle_event_data={props.handle_event_data}
          is_dragging={is_dragging}
        ></Iphone>
      </Canvas>
      <input
        type='file'
        ref={bg_img_ref}
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSelectCoupon(e);
        }}
        accept='image/*'
        style={{ display: 'none' }}
      />
      <ImageCropper
        imageSrc={img_file}
        setCroppedImage={(image) => cropImage(image)}
        image_change={image_change}
      ></ImageCropper>
    </>
  );
};

export default Scene;
